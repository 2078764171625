<template>
  <div class="row">
    <div class="col-xs-12">
      <page-header
        title="Packages"
        :sub-heading="true"
        sub-header="Dashboard / Packages"
        link-name="Create Package"
        link-url="/packages"
      >
      </page-header>

      <full-page-content-container>
        <div class="row">
          <div class="col-xs-12">
            <div class="form">
              <div class="form__group">
                <label for="name" class="form__label">Package Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  class="form__input"
                  placeholder="e.g Starter Package"
                  v-model="formData.name"
                />
              </div>

              <div class="form__group">
                <label for="amount" class="form__label">Amount</label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  class="form__input"
                  placeholder="Specify package amount"
                  v-model="formData.amount"
                />
              </div>

              <div class="form__group">
                <label for="interval" class="form__label">Interval</label>
                <select
                  name="interval"
                  id="interval"
                  class="form__input"
                  v-model="formData.interval"
                >
                  <option value="day">Daily</option>
                  <option value="week" selected>Weekly</option>
                  <option value="month">Monthly</option>
                  <option value="year">Yearly</option>
                </select>
              </div>
              <div class="form__group">
                <label for="btn_text" class="form__label">Details</label>
                <input
                  type="text"
                  name="btn_text"
                  id="btn_text"
                  class="form__input"
                  placeholder="e.g Pay Now"
                  v-model="formData.detail_field"
                  @input="getDetail"
                />
              </div>
              <div class="form__group">
                <div class="details">
                  <div class="details__item" v-for="(item, index) in formData.detail" :key="index">
                    <span>
                      <strong>
                        <span>{{ item }}</span>
                      </strong>
                    </span>
                    <span class="cancel">
                      <i v-if="formData.detail.length > 0" class="fa fa-times" aria-hidden="true" @click="remove(index)"></i>
                    </span>
                    
                  </div>
                </div>
              </div>

              <div class="form__group">
                <label for="btn_text" class="form__label">Button Text</label>
                <input
                  type="text"
                  name="btn_text"
                  id="btn_text"
                  class="form__input"
                  placeholder="e.g Pay Now"
                  v-model="formData.btn_text"
                />
              </div>

              <div class="form__group">
                <label for="user_type" class="form__label">User Type</label>
                <select
                  name="user_type"
                  id="user_type"
                  class="form__input"
                  v-model="formData.user_type"
                >
                  <option value="startup" selected>Startup</option>
                  <option value="investor">Investor</option>
                </select>
              </div>

              <div class="form__group">
                <ca-button :disabled="loading" @click.native="createPackage">
                  <span>Create Package</span>
                  <span v-if="loading"
                    ><i class="fa fa-spinner fa-spin"></i
                  ></span>
                </ca-button>
              </div>
            </div>
          </div>
        </div>
      </full-page-content-container>
    </div>
  </div>
</template>

<script>
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import CaButton from "../../components/buttons/CaButton";
import axios from "../../../axios";

export default {
  name: "CreatePackage",
  components: { CaButton, FullPageContentContainer, PageHeader },
  data() {
    return {
      loading: false,
      formData: {
        name: "",
        amount: "",
        interval: "year",
        btn_text: "",
        detail_field: "",
        detail:[],
        user_type: "startup",
      },
    };
  },
  methods: {
    getDetail() {
      this.formData.detail = this.formData.detail_field.split(",");
    },
    createPackage() {
      const loading = this.$vs.loading();
      this.loading = true;
      if (!this.validateInputs()) {
        loading.close();
        return;
      }
      axios
        .post(`${this.$store.state.server.requestUri}/admin/add-new-package`, {
          name: this.formData.name,
          amount: this.formData.amount,
          interval: this.formData.interval,
          btn_text: this.formData.btn_text,
          detail: this.formData.detail,
          user_type: this.formData.user_type,
        })
        .then(() => {
          this.loading = false;
          loading.close();
           this.$toast(
            `Package created successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          // console.log(res);
        })
        .catch((err) => {
          this.loading = false;
          loading.close();
          console.log(err.response);
        });
    },
    validateInputs() {
      for (let inputValue in this.formData) {
        // console.log(inputValue);
        if (inputValue == "") {
          this.$toast(`You cannot leave any field empty `, {
            position: "top-center",
            type: "error",
          });
          return false;
        }
      }

      return true;
    },
    remove(index) {
      this.formData.detail.splice(index, 1);
      this.formData.detail_field = this.formData.detail.join(",");
    },
  },
};
</script>

<style scoped>
.details{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  vertical-align: middle;
  background-color: #f5f5f5;
  padding: 10px;
  height: auto;
}

.details__item{
  padding: 10px 30px 10px 10px;; 
  background-color: #fff;
  border-radius: 5px;
  height: 50px;
  position: relative;
  margin-bottom: 10px;
  margin: 0 10px 10px 0;

}
.cancel{
  position: absolute;
  right: 4px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
}
</style>
